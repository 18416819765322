import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { useAvailableCountries } from 'hooks/useAvailableCountries';
import { useProspect } from 'hooks/useProspect';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { RegistrationStartupType } from 'types/models';

import { useContinueToken } from './hooks/useContinueToken';
import { useCountry } from './hooks/useCountry';
import { useProspectId } from './hooks/useProspectId';
import { useRedirectUrl } from './hooks/useRedirectUrl';
import { useShopId } from './hooks/useShopId';
import { useSponsorId } from './hooks/useSponsorId';
import {
  IRegistrationContextValues,
  RegistrationContext
} from './RegistrationContext';
import { sortAvailableCountriesByShopId } from './utils/CountriesHelper';
import * as RegistrationHelper from './utils/RegistrationHelper';

interface IRegistrationContextProviderProps {
  readonly children: ReactNode;
}

export function RegistrationContextProvider({
  children
}: IRegistrationContextProviderProps) {
  const urlShopId = useShopId();
  const urlCountry = useCountry();
  const urlSponsorId = useSponsorId();
  const urlProspectId = useProspectId();
  const urlRedirectUrl = useRedirectUrl();
  const urlContinueToken = useContinueToken();
  const {
    i18n: { language }
  } = useTranslation();

  const registrationFlow = RegistrationHelper.getRegistrationFlow({
    urlCountry,
    urlProspectId,
    urlSponsorId,
    urlShopId
  });

  const {
    data: prospectOrHint,
    isLoading,
    error
  } = useProspect(urlProspectId, urlContinueToken);

  const {
    data: availableCountries,
    isLoading: getAvailableCountriesLoading,
    error: getAvailableCountriesError
  } = useAvailableCountries(
    !!registrationFlow &&
      [
        RegistrationStartupType.ShopSpecific,
        RegistrationStartupType.CountrySpecific
      ].includes(registrationFlow)
  );

  const contextValue: IRegistrationContextValues | undefined = useMemo(() => {
    if (
      registrationFlow === RegistrationStartupType.ShopSpecific &&
      availableCountries
    ) {
      const countryShop = availableCountries.find(
        country => country.shopId.toLowerCase() === urlShopId?.toLowerCase()
      );

      if (!countryShop) {
        return;
      }

      const availableCountriesSorted = sortAvailableCountriesByShopId(
        availableCountries,
        countryShop.shopId,
        countryShop.countryCode
      );
      const country = availableCountriesSorted[0].countryCode;

      return {
        redirectUrl: urlRedirectUrl,
        emailRecoveryHint: undefined,
        prospectData: {
          id: undefined,
          locale: language,
          sponsorId: undefined,
          address: countryShop ? { country } : undefined
        },
        resumeToken: urlContinueToken,
        availableCountries,
        registrationFlow
      };
    }

    if (
      registrationFlow === RegistrationStartupType.CountrySpecific &&
      availableCountries
    ) {
      const countryShop = availableCountries.find(
        country =>
          country.countryCode.toLowerCase() === urlCountry?.toLowerCase()
      );

      if (!countryShop) {
        return;
      }

      return {
        redirectUrl: urlRedirectUrl,
        emailRecoveryHint: undefined,
        prospectData: {
          id: undefined,
          locale: language,
          sponsorId: urlSponsorId,
          address: countryShop
            ? { country: countryShop.countryCode }
            : undefined
        },
        resumeToken: urlContinueToken,
        availableCountries: undefined,
        registrationFlow
      };
    }

    if (
      registrationFlow === RegistrationStartupType.ProspectOnly &&
      prospectOrHint
    ) {
      const prospectData =
        prospectOrHint && 'status' in prospectOrHint
          ? { id: urlProspectId }
          : prospectOrHint;
      const prospectResumeData =
        prospectOrHint && 'status' in prospectOrHint
          ? prospectOrHint
          : undefined;

      return {
        redirectUrl: urlRedirectUrl,
        emailRecoveryHint: prospectResumeData?.hint,
        prospectData: prospectData,
        resumeToken: urlContinueToken,
        availableCountries: undefined,
        registrationFlow
      };
    }
  }, [
    availableCountries,
    language,
    prospectOrHint,
    registrationFlow,
    urlContinueToken,
    urlCountry,
    urlProspectId,
    urlRedirectUrl,
    urlShopId,
    urlSponsorId
  ]);

  const isReady = useMemo(
    () => !isLoading && !getAvailableCountriesLoading,
    [getAvailableCountriesLoading, isLoading]
  );

  if (registrationFlow === undefined || getAvailableCountriesError) {
    return <Navigate to={'/404'} />;
  }

  if (error && urlProspectId) {
    if (urlRedirectUrl) {
      window.location.assign(urlRedirectUrl);
      return null;
    }

    return <Navigate to={'/404'} />;
  }

  if (!isReady) {
    return (
      <div className="flex flex-row justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!contextValue) {
    return <Navigate to={'/404'} />;
  }

  return (
    <RegistrationContext.Provider value={contextValue}>
      {children}
    </RegistrationContext.Provider>
  );
}
